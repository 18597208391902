$(document).ready(function() {
    animateOpacity(true);

    $('[data-js="smoothscroll"]').smoothscroll();
    $('.c-carousel-photos__inner').addCarousel({
        hasNav: false,
        itemSelector: '.c-carousel-photo__item',
        responsiveOptions: {
            768: {
                autoWidth: true
            }
        }
    })
    enquire.register("screen and (min-width:" + screensm + ")", {

        match : function() {
            $('.c-carousel-projects__inner').addCarousel({
                hasNav: true,
                itemSelector: '.c-carousel-projects__item',
                navItemSelector: '.c-carousel-projects-nav__item',
                navLinkSelector: '.c-carousel-projects-nav__link',
                responsiveOptions: {
                    768: {
                        autoWidth: true
                    }
                }
            })
        },
        unmatch: function() {
            $('.c-carousel-projects__inner').addCarousel('destroy');
        }
    });
});

var controller;
var screenxs = '580px';
var screensm = '768px';
var screenmd = '1024px';
var screenlg = '1200px';
var screenxl = '1400px';

(function($) {

    var PLUGIN_NAME = 'addCarousel';



    var Plugin = function(element, options) {

        this.$element = $(element);

        this.config = $.extend({}, Plugin.DEFAULTS, options);

        this.init();

    };



    Plugin.DEFAULTS = {

        hasNav: false,

        itemSelector: '.c-carousel-projects__item',

        navItemSelector: '.c-carousel-projects-nav__item',

        navLinkSelector: '.c-carousel-projects-nav__link',

        responsiveOptions: {}

    };



    Plugin.prototype = {

        config: this.config,

        $element: this.$element,

        carousel: null,



        init: function() {

            var self = this;



            self.carousel = self.$element;



            //setup carousel

            self.carousel.owlCarousel({

                nav: true,

                smartSpeed: 400,

                navText: [

                    '<i class="c-icon-arrow-left"></i>',

                    '<i class="c-icon-arrow-right"></i>'

                ],

                items: 1,

                responsive : self.config.responsiveOptions

            });



            if(self.config.hasNav) {

                //on change update nav

                self.carousel.on('translated.owl.carousel', function(event){

                    self._updateNav(event);

                })

                //on nav click go to item

                $(self.config.navLinkSelector).click(function(e){

                    e.preventDefault();

                    self._goToItem($(this))

                })

            }

        },

        _updateNav: function(event) {

            var self = this;

            var itemIndex = event.item.index;

            var maximum = event.relatedTarget.maximum();

            var currentProject = $(self.config.itemSelector + ':eq(' + itemIndex + ')').data('project');



            //if at end of carousel > last item is active

            if (itemIndex == maximum) {

                $(self.config.navLinkSelector).removeClass('is-active');

                $(self.config.navItemSelector + ':last-child').find(self.config.navLinkSelector).addClass('is-active');

            //else check for hash

            } else if (currentProject != undefined ) {

                $(self.config.navLinkSelector).removeClass('is-active');

                $(self.config.navLinkSelector + '[href="#' + currentProject + '"]').addClass('is-active');

            }

        },

        _goToItem: function(trigger) {

            var self = this;



            var href = trigger.attr('href');

            var index = $(href).closest('.owl-item').index();

            self.$element.trigger('to.owl.carousel', [index,400,true]);

        },

        destroy: function() {

            var self = this;

            self.carousel.trigger('destroy.owl.carousel');

            self.$element.removeData('plugin_' + PLUGIN_NAME);

        }

    };



    $.fn[PLUGIN_NAME] = function(options) {



        if (options === undefined || typeof options === 'object') {

            return this.each(function() {

                if (!$.data(this, 'plugin_' + PLUGIN_NAME)) {

                    $.data(this, 'plugin_' + PLUGIN_NAME, new Plugin(this, options));

                }

            });

        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {

            var returns;

            this.each(function() {

                var instance = $.data(this, 'plugin_' + PLUGIN_NAME);

                if (instance instanceof Plugin && typeof instance[options] === 'function') {

                    if (options === 'destroy') {

                        $.data(this, 'plugin_' + PLUGIN_NAME, null);

                    }

                    returns = instance[options].apply(instance);

                } else if (!(instance instanceof Plugin)) {

                    //do nothing

                } else {

                    throw new Error('The function "' + options + '" does not excist');

                }

            });

            return returns !== undefined ? returns : this;

        } else if (options[0] === '_' || options === 'init') {

            throw new Error('The function "' + options + '" is not public');

        } else {

            throw new Error("Something goes wrong");

        }

    };

})(jQuery);


$('.c-scrollarrow').click(function() {
	$('html, body').animate({
		scrollTop: $(window).scrollTop() + $(window).height()
	}, 500);
});
$(window).scroll(function() {
	if ($(window).scrollTop() >= ($(document).height() - $(window).height())) {
		$('.c-scrollarrow').hide();
	} else {
		$('.c-scrollarrow').show();
	}
});

(function($) {
    var PLUGIN_NAME = 'smoothscroll';

    var Plugin = function(element, options) {
        this.$element = $(element);
        this.metadata = {
            "target":  $(element).data(PLUGIN_NAME + '-target'),
            "offset":  $(element).data(PLUGIN_NAME + '-offset')
        }
        this.config = $.extend({}, Plugin.DEFAULTS, options, this.metadata);
        this.init();
    };

    Plugin.DEFAULTS = {
        target: null,
        offset: 0,
        speed: 600
    };

    Plugin.prototype = {
        config: this.config,
        $element: this.$element,
        $target: null,

        init: function() {
            var self = this;
            self.$target = self._getTarget();

            self.$element.click(function(e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: self.$target.offset().top + parseInt(self.config.offset)
                }, self.config.speed);
            });
        },
        _getTarget: function() {
            var self = this;
            //if set via data
            var target = self.config.target;

            //if not, get href
            if (!target) {
                target = self.$element.attr('href');
            }
            return $(target);
        }

    };

    $.fn[PLUGIN_NAME] = function(options) {

        if (options === undefined || typeof options === 'object') {
            return this.each(function() {
                if (!$.data(this, 'plugin_' + PLUGIN_NAME)) {
                    $.data(this, 'plugin_' + PLUGIN_NAME, new Plugin(this, options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
            var returns;
            this.each(function() {
                var instance = $.data(this, 'plugin_' + PLUGIN_NAME);
                if (instance instanceof Plugin && typeof instance[options] === 'function') {
                    if (options === 'destroy') {
                        $.data(this, 'plugin_' + PLUGIN_NAME, null);
                    }
                    returns = instance[options].apply(instance);
                } else if (!(instance instanceof Plugin)) {
                    //do nothing
                } else {
                    throw new Error('The function "' + options + '" does not excist');
                }
            });
            return returns !== undefined ? returns : this;
        } else if (options[0] === '_' || options === 'init') {
            throw new Error('The function "' + options + '" is not public');
        } else {
            throw new Error("Something goes wrong");
        }
    };
})(jQuery);


function shuffleArray(array) {

    var currentIndex = array.length,

        temporaryValue, randomIndex;



    // While there remain elements to shuffle...

    while (0 !== currentIndex) {



        // Pick a remaining element...

        randomIndex = Math.floor(Math.random() * currentIndex);

        currentIndex -= 1;



        // And swap it with the current element.

        temporaryValue = array[currentIndex];

        array[currentIndex] = array[randomIndex];

        array[randomIndex] = temporaryValue;

    }

    return array;

}


function animateOpacity(direction) {
    //create TL
    var imageOpacityTL = new TimelineMax({
        paused: true,
        //on complete call itself with reverse direction
        onComplete: function() {
            animateOpacity(!direction);
        }
    });
    //shuffle array of elems
    var $photoElems = shuffleArray($('.c-grid-photos__image--overlay'));
    //add tweens
    $photoElems.each(function() {
        imageOpacityTL.to($(this), 1.0, {
            css: {
                className: ((direction) ? '+' : '-') + '=is-hidden'
            }
        }, '-=0.6')
    });
    //play TL
    imageOpacityTL.play();
}

